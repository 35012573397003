import React from "react";
import { Avatar } from "antd";
import Mapa from "../components/Mapa";
import logo from "../images/png/avatar-black.png";

import MapCard from "../components/Mapa/MapaCard";

const MapPage = () => (
  <div>
    <Avatar src={logo} />
    <Mapa />
    <MapCard />
  </div>
);
export default MapPage;
